var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',_vm._b({ref:"autocomplete",attrs:{"search-input":_vm.editSearch,"filter":_vm.filterFn},on:{"update:searchInput":function($event){_vm.editSearch=$event},"update:search-input":function($event){_vm.editSearch=$event},"change":_vm.showmeEnter,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.showmeEnter.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item",fn:function({ item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.propsObject[`item-text`] ? item[`${_vm.propsObject[`item-text`]}`] : item)+" ")])],1),_vm._t("appendItem",null,{"item":item})],2)]}},{key:"selection",fn:function({ item, index }){return [_vm._t("selection",function(){return [_c('span',[(!_vm.propsObject.noShorten && _vm.showOthers && index === 0)?_c('v-chip',{staticClass:"k-reset text-caption",attrs:{"small":"","color":"transparent"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.showOthers = false}}},[_c('div',{staticClass:"primary--text blueHover"},[_vm._v("(collapse)")])]):_vm._e(),(
						_vm.propsObject.noShorten ||
						index < _vm.propsObject.maxChips ||
						_vm.showOthers
					)?_c('v-chip',{key:`gameKey-${
						_vm.propsObject[`item-value`]
							? item[`${_vm.propsObject[`item-value`]}`]
							: item
					}`,attrs:{"small":"","close":""},on:{"click:close":function($event){return _vm.removeElement(index)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.propsObject[`item-text`] ? item[`${_vm.propsObject[`item-text`]}`] : item)+" ")])]):_vm._e(),(
						!_vm.propsObject.noShorten &&
						index === _vm.propsObject.maxChips &&
						!_vm.showOthers
					)?_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"text-caption",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.showOthers = true}}},[_c('span',{staticClass:"primary--text blueHover"},[_vm._v(" (+"+_vm._s(_vm._selected.length - _vm.propsObject.maxChips)+" "+_vm._s(_vm._selected.length - _vm.propsObject.maxChips > 1 ? "others" : "other")+") ")])]):_vm._e(),(
						!_vm.propsObject.noShorten &&
						_vm.showOthers &&
						index === _vm._selected.length - 1
					)?_c('v-chip',{staticClass:"k-reset text-caption",attrs:{"small":"","color":"transparent"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.showOthers = false}}},[_c('div',{staticClass:"primary--text blueHover"},[_vm._v("(collapse)")])]):_vm._e()],1)]},{"item":item,"index":index})]}},{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"ma-3"},[_c('v-chip',{staticClass:"mr-2",attrs:{"disabled":_vm._selected.length === _vm.propsObject.items.length,"small":""},on:{"click":function($event){return _vm.toggleAll(true)}}},[_vm._v(" Select All "+_vm._s(_vm._selected.length == 0 && _vm.editSearch && _vm.editSearch?.length > 0 ? "Filtererd" : "")+" ")]),_c('v-chip',{attrs:{"disabled":_vm._selected.length === 0,"small":""},on:{"click":function($event){return _vm.toggleAll(false)}}},[_vm._v(" Deselect All "+_vm._s(_vm._selected.length == 0 && _vm.editSearch && _vm.editSearch?.length > 0 ? "Filtererd" : "")+" ")])],1)]},proxy:true}],null,true),model:{value:(_vm._selected),callback:function ($$v) {_vm._selected=$$v},expression:"_selected"}},'v-autocomplete',{ ..._vm.propsObject, ..._vm.$attrs },false))
}
var staticRenderFns = []

export { render, staticRenderFns }