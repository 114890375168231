<!-- TODO: [L2-106] Create clickable detail -->
<template>
	<v-container
		id="Paid_bonuses"
		fluid
		tag="section">
		<v-overlay
			z-index="60"
			v-model="loaderlay">
			<v-progress-circular
				indeterminate
				size="100"
				color="primary"
				class="l2">
				<v-progress-circular
					indeterminate
					size="75"
					color="primary"
					class="l3"></v-progress-circular>
			</v-progress-circular>
		</v-overlay>
		<FilterSideBar
			:refreshButton="true"
			@refresh="_loadTable"
			:key="currentCasino"
			ref="filterSide"
			@opened="updateFilterData"
			@clear="onClear"
			:loading="loading"
			:width="isMobile ? '' : '450px'"
			:filterIsEmpty="filterIsEmpty">
			<FilterForm
				@useFilter="useFilter"
				@update="onFilterUpdate"
				v-model="sessionFilter"
				:loading="loading"
				:filterIsActive="filterIsActive"
				ref="filterForm"></FilterForm>
		</FilterSideBar>
		<v-row dense>
			<v-col>
				<v-card class="v-card--material true pa-3">
					<card-heading
						:color="'secondary'"
						:title="'Paid bonuses'">
						<slot name="addItem"></slot>
						<v-tooltip
							bottom
							color="primary darken-2">
							<template v-slot:activator="{ on: tooltip }">
								<v-btn
									elevation="1"
									fab
									small
									v-on="tooltip"
									color="primary"
									@click="onExportOpen">
									<v-icon>mdi-arrow-down-bold-circle</v-icon>
								</v-btn>
							</template>
							<span>Export tickets</span>
						</v-tooltip>
					</card-heading>
					<v-data-table
						:headers="headers"
						:items="list"
						:options.sync="options"
						:server-items-length="total_records"
						:item-class="itemClass"
						must-sort
						sort-by="created"
						:sort-desc="true"
						:loading="loading"
						:footer-props="footerProps"
						class="elevation-1">
						<template #top="{ pagination }">
							<v-data-footer
								class="anton"
								:options.sync="options"
								v-bind="footerProps"
								:pagination="pagination"></v-data-footer>
						</template>
						<template v-slot:[`item.level_name`]="{ item }">
							<span class="mr-2">{{ item.level_name | Capitalize }}</span>
							<v-btn
								v-if="getBorder(item) != false"
								v-bind:style="{
									width: `20px`,
									height: `20px`,
									background: getBorder(item) + 'border-box',
								}"
								@click="rotateMe"
								class="elevation-0"
								fab></v-btn>
						</template>
						<template v-slot:[`item.amount`]="{ item }">
							{{ item.amount | Number({ currency: item.currency }) }}
						</template>
						<template v-slot:[`item.player_mongo_id`]="{ item }">
							<span
								class="hoverTheme body-2 font-weight-bold primary--text pa-1"
								@click.prevent="toPlayer(item)">
								{{ item.player_mongo_id }}
							</span>
						</template>
						<template v-slot:[`item.created`]="{ item }">
							{{ item.created | Date }}
						</template>
						<template v-slot:[`item.collected`]="{ item }">
							{{ item.collected | Date }}
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
		<v-dialog
			@click:outside="exportClose"
			@keydown.esc="exportClose"
			max-width="500px"
			v-model="exportDialog">
			<ExportDialog
				@close="exportClose"
				builder="tickets"
				:data="ticketsData"
				filename="testExport.csv"
				ref="ticketsExport"></ExportDialog>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import cardHeading from "../../components/shared/CardHeading.vue";
import FilterSideBar from "../../components/shared/FilterSideBar.vue";
import FilterForm from "../../components/shared/FilterForm.vue";
import ExportDialog from "../../components/shared/ExportDialog.vue";
import permissions from "../../mixins/permissions";
import table from "../../mixins/table";
import _ from "lodash";
import localstorage from "../../mixins/localstorage";
import { casinoChanged, isMobile } from "../../constants/helpers";

export default {
	components: {
		cardHeading,
		FilterSideBar,
		FilterForm,
		ExportDialog,
	},
	mixins: [permissions, table, localstorage],
	name: "Paid_Bonuses",
	created() {
		this.$syncWithStorage(["currentCasino", "sessionFilter"], this.$route.name);
		this.previousCasino = this.$getFromStorage("currentCasino");
		const tmpFilter = this.$getFromStorage("sessionFilter");
		if (tmpFilter) {
			this.sessionFilter = tmpFilter;
		}

		this.sessionFilter.selectedCasinos = casinoChanged(
			this.previousCasino,
			this.currentCasino,
			this.sessionFilter?.selectedCasinos
		);
	},
	data: () => {
		return {
			previousCasino: undefined,
			loaderlay: false,
			filterValues: {},
			sessionFilter: {
				showUncollected: true,
				showCollected: true,
				collected_from: undefined,
				collected_to: undefined,
				created_from: undefined,
				created_to: undefined,
				amount_from: undefined,
				amount_to: undefined,
				selectedLevels: [],
				selectedGroups: [],
				selectedCasinos: [],
				selectedCurrencies: [],
			},
			rowIsClickable: false,
			exportDialog: false,
			filterObject: {
				amount: { ge: undefined, le: undefined },
				created: { ge: undefined, le: undefined },
				collected: { ge: undefined, le: undefined },
				group_name: { of: undefined },
				level_name: { of: undefined },
				currency: { of: undefined },
				categories: [],
			},
			headers: [
				{ text: "ID", value: "id", align: "center" },
				{ text: "Group", value: "group_name", align: "center" },
				{ text: "Casino ID", value: "player_casino_id", align: "center" },
				{ text: "Level", value: "level_name", align: "right" },
				{ text: "Player ID", value: "player_mongo_id", align: "center" },
				{ text: "Amount", value: "amount", align: "right" },
				{ text: "Created", value: "created", align: "center" },
				{ text: "Collected", value: "collected", align: "center" },
			],
		};
	},
	computed: {
		isMobile,
		ticketsData() {
			const res = {
				url: this.workerUrl,
				authToken: this.authToken,
				filter: this.filter,
			};
			return [
				[
					"id",
					"group_name",
					"player_casino_id",
					"level_name",
					"player_mongo_id",
					"amount",
					"currency",
					"created",
					"collected",
				],
				res,
			];
		},
		...mapGetters("jackpotGroup2", {
			cToGMap: "casinoGroupMap",
			allowedGroups: "getFilteredGroups",
		}),
		...mapGetters({ workerUrl: "loganUrl" }),
		...mapGetters("apiCall", { authToken: "accessToken" }),
		...mapGetters("ticket", {
			list: "list",
			pagination: "pagination",
			filter: "filter",
		}),
		...mapGetters({
			currentCasino: "currentCasino",
		}),
		...mapGetters({
			allowedCasinos: "allowedCasinos",
			casinoCategoryMap: "casinoCategoryMap",
		}),
		filterIsEmpty() {
			const tempFilter = { ...this.filter };
			const tempObject = { ...this.filterObject };
			_.set(tempObject, "player_casino_id.of", [null, this.currentCasino]);
			tempObject.group_name.of = this.cToGMap.get(this.currentCasino) ?? [];
			return _.isEqual(tempFilter, tempObject);
		},
		filterIsActive() {
			return _.isEqual(this.filter, this.filterValues);
		},
	},

	methods: {
		rotateMe(e) {
			e.target.classList.add("rotateMe");
			setTimeout(() => {
				e.target.classList.remove("rotateMe");
			}, 200);
		},
		getBorder(item) {
			if (!item.theme_settings || !item.theme_settings[item.level_name])
				return false;

			const setting = item.theme_settings[item.level_name];

			if (!setting.borderColors || !setting.borderColorStops) return false;
			if (
				!Array.isArray(setting.borderColors) ||
				!Array.isArray(setting.borderColorStops)
			)
				return false;
			if (
				setting.borderColors.length === 0 ||
				setting.borderColorStops.length === 0
			)
				return false;

			return `${this.createCssGradient(
				setting.borderColors,
				setting.borderColorStops
			)} 10% 10%`;
		},
		asyncRouterPush(route) {
			return new Promise((resolve) => {
				resolve(this.$router.push({ path: route }));
			});
		},
		createCssGradient(colorArr, colorStopsArr) {
			let tmpBorderGradient = "";
			let tmpLength = colorArr.length;
			if (tmpLength === 1)
				return `linear-gradient(to bottom, ${colorArr[0]}, ${colorArr[0]})`;
			for (let i = 0; i < tmpLength; i++) {
				tmpBorderGradient += colorArr[i] + " " + colorStopsArr[i] + "% ";
				if (i + 1 < tmpLength) {
					tmpBorderGradient += ", ";
				}
			}
			return " linear-gradient(to bottom, " + tmpBorderGradient + ")";
		},
		async toPlayer(item) {
			this.loaderlay = true;

			try {
				await this.$router.push({
					name: "player-detail",
					params: {
						player_id: `${item.player_mongo_id}`,
						breadcrumbs: { title: `Player ${item.player_mongo_id}` },
						casino: item.player_casino_id,
					},
				});
			} catch (error) {
				this.loaderlay = false;
			}
		},
		onExportOpen() {
			this.exportDialog = true;
			setTimeout(() => {
				this.$refs.ticketsExport.onExportOpen();
			}, 500);
		},
		exportClose(e) {
			this.exportDialog = false;
			this.$refs.ticketsExport.onClose();
		},
		...mapActions("ticket", {
			loadList: "loadFilteredList",
		}),
		...mapMutations("ticket", {
			setPagination: "pagination",
			setFilter: "filter",
		}),
		updateFilterData() {
			this.$refs.filterForm.updateFilterData();
		},
		async onClear() {
			this.$refs.filterForm.clearFilter();
			await this.$nextTick();
			this.useFilter();
		},
		async onFilterUpdate(val) {
			this.filterValues = {
				amount: {
					ge: val.amount_from ? val.amount_from * 100 : undefined,
					le: val.amount_to ? val.amount_to * 100 : undefined,
				},
				categories: val.selectedCategories,
				created: { ge: val.created_from, le: val.created_to },
				collected: { ge: val.collected_from, le: val.collected_to },
				level_name: {
					of: val.selectedLevels.length != 0 ? val.selectedLevels : undefined,
				},
				currency: {
					of:
						val.selectedCurrencies.length != 0
							? val.selectedCurrencies
							: undefined,
				},
			};

			var casinos = [...val.selectedCasinos];

			if (!val.selectedCasinos || val.selectedCasinos?.length == 0) {
				if (!val.selectedCategories || val.selectedCategories?.length == 0) {
					casinos = this.allowedCasinos.map((el) => el.casino_id);
				} else {
					const cass = val.selectedCategories.reduce((agg, curr) => {
						agg.push(
							this.casinoCategoryMaval.get(curr)
								? this.casinoCategoryMap.get(curr).map((el) => el.casino_id)
								: []
						);
						return agg;
					}, []);
					casinos = _.intersection(...cass);
				}
			}

			//GROUPS POSSIBLE FROM CASINOS
			const possibleGroups = casinos.reduce(
				(acc, curr) => (acc = _.union(acc, this.cToGMap.get(curr))),
				undefined
			);
			//GROUPS PICKED BY USER
			const groups =
				val.selectedGroups.length != 0 ? val.selectedGroups : undefined;
			//SHOW NULL CASINOS HELPER
			const leader =
				val.showUncollected && possibleGroups?.length > 0 ? [null] : [];

			//ADD CASINOS TO FILTER AND NULL FROM LAST STEP
			this.filterValues.player_casino_id = {
				of: casinos.length != 0 ? leader.concat(casinos) : undefined,
			};

			//IF SHOWUNCOLLECTED IS DISABLED ADD NOT EQUAL = NULL TO FILTER
			if (!val.showUncollected) {
				this.filterValues.player_casino_id.ne = null;
			}
			//IF SHOWCOLLECTED IS DISABLED SET CASINO_ID FILTER TO EQUALS NULL
			//IF BOTH ARE DISABLED DONT SHOW ANYTHING
			if (!val.showCollected) {
				this.filterValues.player_casino_id = { eq: null };
				if (!val.showUncollected) {
					this.filterValues.collected = { eq: null, ne: null };
				}
			}
			//IF USER HAS SELECTED BOTH GROUPS AND CASINOS
			if (
				possibleGroups &&
				groups &&
				possibleGroups.length > 0 &&
				groups.length > 0
			) {
				const interSection = _.intersection(possibleGroups, groups);
				//DONT SHOW ANYTHING IF THEIR GROUPS DONT INTERSECT
				if (interSection.length == 0) {
					this.filterValues.collected = { eq: null, ne: null };
				} else {
					//IF THEIR GROUPS INTERSECT USE THE INTERSECTION
					this.filterValues.group_name = {
						of: interSection,
					};
				}
			} else {
				//IF ONLY ONE OPTION IS SELECTED USE THAT ONE
				this.filterValues.group_name = {
					of: groups ? groups : possibleGroups,
				};
			}
			await this.useFilter();
		},
		async useFilter() {
			this.setFilter(this.filterValues);
			if (this.options.page === 1) {
				this._loadTable();
			} else {
				this.options.page = 1;
			}
		},
	},
	watch: {
		currentCasino: {
			handler(next, prev) {
				this.sessionFilter.selectedCasinos = casinoChanged(
					prev,
					next,
					this.sessionFilter?.selectedCasinos
				);
			},
		},
	},
};
</script>

<style scoped lang="sass">

.rotateMe
	transform: rotateY(180deg)

.hoverTheme:hover
	background-color: var(--v-primary-base)
	border-radius: 10px
	color: var(--v-kajot-text-base) !important
	-webkit-user-select: none
	-ms-user-select: none
	user-select: none
	cursor: pointer

.anton
	border: 0 !important


::v-deep
	@for $i from 1 through 3
		& .l#{$i}>svg
			$dur:4s
			$offset: .3
			$delay: ($i * $offset) - 3s
			animation-duration: $dur
			animation-delay: $delay
			animation-direction: alternate
			animation-timing-function: ease-in-out
			@if $i % 2 == 0
				animation-direction: alternate-reverse
			.v-progress-circular__overlay
				animation-duration: $dur
				animation-delay: $delay
				animation-direction: alternate
				animation-timing-function: ease-in-out
				@if $i % 2 == 0
					animation-direction: alternate-reverse
</style>
